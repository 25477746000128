"use client";

import dynamic from "next/dynamic";

const PageNotFoundMessage = dynamic(() => import("../not_pages/errors/PageNotFoundMessage/PageNotFoundMessage"), {
    ssr: false,
});

const PageNotFound = () => {
    return <PageNotFoundMessage />;
};

export default PageNotFound;
